import { useMatches } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { useQuery } from 'urql';
import { NotFound, StandardError } from '~/components/errors';
import { Loading } from '~/components/parts';
import { BottomNav } from '~/components/shared';
import { graphql } from '~/gql/generated';
import styles from '~/styles/page/mypage/deals/dealsShow/deals-show.module.css';
import { DealStatus } from './DealStatus';
import { InspectionCertificate } from './InspectionCertificate';
import { NotificationCard } from './NotificationCard';
import { OrderCar } from './OrderCar';
import { PaymentCard } from './PaymentCard';

const blockClass = 'deals-show';

const mypageDealShowPageQueryDocument = graphql(`
  query MypageDealShowPage($dealId: ID!) {
    deal(id: $dealId) {
      id
      user {
        id
        fullName
      }
      salesStaff {
        id
        fullName
        imageUrl
      }
      ...NotificationCard_deal
      ...OrderCar_deal
      ...DealStatus_deal
      ...InspectionCertificate_deal
      ...PaymentCard_deal
    }
  }
`);

export const MypageDealsShow: FunctionComponent = () => {
  const matches = useMatches();
  const { params } = matches[1];
  const [{ data, fetching, error }] = useQuery({
    query: mypageDealShowPageQueryDocument,
    variables: { dealId: params.id || '' }
  });
  const staff = data?.deal?.salesStaff;

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <StandardError />;
  }

  if (!data?.deal) {
    return <NotFound />;
  }

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__head`]}>
        <div className={styles[`${blockClass}__head-inner`]}>
          <p className={styles[`${blockClass}__name`]}>{data?.deal?.user?.fullName}</p>
          <p className={styles[`${blockClass}__title`]}>納車までの流れ</p>
          {staff &&
          <div className={styles[`${blockClass}__staff`]}>
              <img
              src={staff.imageUrl || '/images/pictures/no_image.png'}
              alt='icon'
              className={styles[`${blockClass}__staff-icon`]} />

              <p>担当：{staff.fullName}</p>
            </div>
          }
        </div>
      </div>
      <div className={styles[`${blockClass}__inner`]}>
        <NotificationCard deal={data?.deal} />
      </div>
      <PaymentCard deal={data?.deal} />
      <InspectionCertificate deal={data?.deal} />
      <OrderCar deal={data?.deal} />
      <DealStatus deal={data?.deal} />
      <BottomNav />
    </div>);

};