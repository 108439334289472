import { type FunctionComponent } from 'react';
import styles from '~/styles/page/mypage/deals/dealsShow/status-bar.module.css';
import { MypageDealTaskGroupEnum } from '~/gql/generated/graphql';

const blockClass = 'status-bar';

type Props = {
  defaultGroup: MypageDealTaskGroupEnum;
  group: MypageDealTaskGroupEnum;
  clickableDeliveryCompleted: boolean;
  onChangeGroup: (value: MypageDealTaskGroupEnum) => void;
};

export const StatusBar: FunctionComponent<Props> = ({
  defaultGroup,
  group,
  clickableDeliveryCompleted,
  onChangeGroup
}) => {
  return (
    <div className={styles[blockClass]}>
      <div
        className={[
        styles[`${blockClass}__item`],
        styles[`${blockClass}__item--active`],
        defaultGroup === MypageDealTaskGroupEnum.DocumentCreating ? styles[`${blockClass}__item--current`] : ''].
        join(' ')}
        onClick={() => onChangeGroup(MypageDealTaskGroupEnum.DocumentCreating)}>

        <div className={`${styles[`${blockClass}__item-image`]} ${styles[`${blockClass}__item-image--document`]} `} />
        <p className={styles[`${blockClass}__item-text`]}>
          申込書類
          <br />
          作成中
        </p>
      </div>
      <div
        className={[
        styles[`${blockClass}__item`],
        [
        MypageDealTaskGroupEnum.RegistrationApplication,
        MypageDealTaskGroupEnum.DeliveryPreparation,
        MypageDealTaskGroupEnum.DeliveryCompletion].
        includes(group) ?
        styles[`${blockClass}__item--active`] :
        '',
        defaultGroup === MypageDealTaskGroupEnum.RegistrationApplication ? styles[`${blockClass}__item--current`] : ''].
        join(' ')}
        onClick={() => onChangeGroup(MypageDealTaskGroupEnum.RegistrationApplication)}>

        <div
          className={`${styles[`${blockClass}__item-image`]} ${styles[`${blockClass}__item-image--pc`]} ${
          group === MypageDealTaskGroupEnum.RegistrationApplication ? styles[`${blockClass}__item-image--active`] : ''}`
          } />

        <p className={styles[`${blockClass}__item-text`]}>登録申請中</p>
      </div>
      <div
        className={[
        styles[`${blockClass}__item`],
        [MypageDealTaskGroupEnum.DeliveryPreparation, MypageDealTaskGroupEnum.DeliveryCompletion].includes(group) ?
        styles[`${blockClass}__item--active`] :
        '',
        defaultGroup === MypageDealTaskGroupEnum.DeliveryPreparation ? styles[`${blockClass}__item--current`] : ''].
        join(' ')}
        onClick={() => onChangeGroup(MypageDealTaskGroupEnum.DeliveryPreparation)}>

        <div className={`${styles[`${blockClass}__item-image`]} ${styles[`${blockClass}__item-image--car`]}`} />
        <p className={styles[`${blockClass}__item-text`]}>納車準備中</p>
      </div>
      <div
        className={[
        styles[`${blockClass}__item`],
        group === MypageDealTaskGroupEnum.DeliveryCompletion ? styles[`${blockClass}__item--active`] : '',
        defaultGroup === MypageDealTaskGroupEnum.DeliveryCompletion ? styles[`${blockClass}__item--current`] : ''].
        join(' ')}
        onClick={() => clickableDeliveryCompleted && onChangeGroup(MypageDealTaskGroupEnum.DeliveryCompletion)}>

        <div className={`${styles[`${blockClass}__item-image`]} ${styles[`${blockClass}__item-image--done`]}`} />
        <p className={styles[`${blockClass}__item-text`]}>納車完了</p>
      </div>
    </div>);

};