import { useParams } from '@remix-run/react';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useQuery } from 'urql';
import { Loading } from '~/components/parts';
import { graphql } from '~/gql/generated';
import styles from '~/styles/page/mypage/content-link-show.module.css';

const fetchContentLinkQueryDocument = graphql(`
  query ContentLinkShow($key: String!) {
    contentLink(key: $key) {
      id
      contentable {
        urlToRedirect
        id
      }
    }
  }
`);

export const ContentLinkShow: FunctionComponent = () => {
  const params = useParams();
  const contentLinkKey = params.key;
  const [{ data, fetching }] = useQuery({
    query: fetchContentLinkQueryDocument,
    variables: { key: contentLinkKey! },
    pause: !contentLinkKey
  });
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (fetching) return;
    const urlToRedirect = data?.contentLink?.contentable?.urlToRedirect;
    if (!urlToRedirect) {
      setIsError(true);
      return;
    }
    window.location.replace(urlToRedirect);
  }, [data?.contentLink?.contentable?.urlToRedirect, fetching]);

  if (!contentLinkKey || isError) {
    return (
      <div className={styles.error}>
        <p>URLが見つかりませんでした。</p>
        <p>ページが移動したか、削除された可能性があります。</p>
        <p>お手数ですが、URLを再度ご確認してください。</p>
      </div>);

  }

  return <Loading />;
};