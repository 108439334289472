import { Link, useNavigate } from '@remix-run/react';
import { useEffect, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import { graphql } from '~/gql/generated';
import { type UpdateUserName_UserEditMutation } from '~/gql/generated/graphql';
import { useAuth } from '~/hooks';
import { useErrorFlashContext } from '~/providers';
import styles from '~/styles/page/mypage/user-edit.module.css';
import { errorHandle } from '~/utils/graphql/errorHandle';

const updateUserNameMutationDocument = graphql(`
  mutation UpdateUserName_UserEdit($input: UpdateUserNameInput!) {
    updateUserName(input: $input) {
      user {
        id
        lastName
        firstName
      }
      errors {
        message
        path
      }
    }
  }
`);

type Inputs = {
  lastName: string;
  firstName: string;
};

const blockClass = 'user-edit';

export const UserNameEdit: FunctionComponent = () => {
  const { user } = useAuth();
  const [, updateUserName] = useMutation(updateUserNameMutationDocument);
  const { open: openErrorFlash } = useErrorFlashContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<Inputs>();

  useEffect(() => {
    if (user) {
      setValue('lastName', user.lastName || '');
      setValue('firstName', user.firstName || '');
    }
  }, [setValue, user]);

  const submit = async (data: Inputs) => {
    const { lastName, firstName } = data;
    const result = await updateUserName({ input: { lastName, firstName } });
    const { hasError: hasErrorUpdateUser } = errorHandle<UpdateUserName_UserEditMutation['updateUserName']>(
      result.data?.updateUserName,
      result.error
    );

    if (hasErrorUpdateUser) {
      openErrorFlash();
      return;
    }

    navigate('/mypage/callback');
  };

  return (
    <div className={styles.main}>
      <p className={styles.title}>お客様マイページの発行</p>
      <div className={styles.description}>
        <p>納車に向けた進捗状況をお客様と共有するためのマイページを発行します。</p>
        <p>お名前を入力し、送信を完了してください。</p>
      </div>

      <form onSubmit={handleSubmit(submit)} className={styles[blockClass]}>
        <div className={styles['form-item']}>
          <div className={styles['form-label']}>
            <p className={styles['form-label__mark']}>必須</p>
            <p className={styles['form-label__text']}>名前</p>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['form-group']}>
              <input
                {...register('lastName', { required: true })}
                className={styles['form-input']}
                placeholder='中野' />

              {errors.lastName && <p className={styles['form-error']}>姓を入力してください</p>}
            </div>
            <div className={styles['form-group']}>
              <input
                {...register('firstName', { required: true })}
                className={styles['form-input']}
                placeholder='優作' />

              {errors.firstName && <p className={styles['form-error']}>名を入力してください</p>}
            </div>
          </div>
        </div>

        <p className={styles.terms}>
          「送信」をクリックすることで、当社の<Link to='/terms'>利用規約</Link>及び
          <Link to='/privacy'>プライバシーポリシー</Link>に同意したものとみなします。
        </p>

        <button type='submit' className={styles.button}>
          送信
        </button>
      </form>
    </div>);

};