import { type FunctionComponent } from 'react';
import { BottomNav } from '~/components/shared';
import { useAuth } from '~/hooks';
import styles from '~/styles/page/mypage/deals/dealsEmpty/deal-empty.module.css';

const blockClass = 'deal-empty';

export const DealsEmpty: FunctionComponent = () => {
  const { user } = useAuth();

  return (
    <div className={styles[blockClass]}>
      {(user?.lastName || user?.firstName) &&
      <p className={styles[`${blockClass}__name`]}>{`${user?.lastName || ''} ${user?.firstName || ''}`}さん</p>
      }
      {user?.lineAccount?.name &&
      <p className={styles[`${blockClass}__line-name`]}>LINE ID：{user.lineAccount.name}</p>
      }
      <hr className={styles[`${blockClass}__hr`]} />
      <p className={styles[`${blockClass}__title`]}>あなたのお車</p>
      <p>取引中のお車はありません。</p>
      <BottomNav />
    </div>);

};