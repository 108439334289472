import { useNavigate } from '@remix-run/react';
import { useEffect, type FunctionComponent } from 'react';
import { useQuery } from 'urql';
import { Loading } from '~/components/parts';
import { graphql } from '~/gql/generated';

const mypageCallbackPageQueryDocument = graphql(`
  query MypageCallbackPage {
    viewer {
      id
      lastName
      firstName
      publicDeals {
        id
      }
    }
  }
`);

export const MypageCallback: FunctionComponent = () => {
  const [{ data, fetching }] = useQuery({ query: mypageCallbackPageQueryDocument });
  const navigate = useNavigate();

  useEffect(() => {
    if (fetching) return;

    if (!data?.viewer?.lastName || !data?.viewer?.firstName) {
      navigate('/mypage/user_name_edit');
      return;
    }

    if (!data?.viewer?.publicDeals?.length) {
      navigate('/mypage/deals/empty');
      return;
    }

    navigate(`/mypage/deals/${data.viewer.publicDeals[0].id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  return <Loading />;
};
