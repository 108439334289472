import { useState, type FunctionComponent, useEffect } from 'react';
import styles from '~/styles/page/mypage/deals/dealsShow/deal-status.module.css';
import { StatusBar } from './StatusBar';
import { TaskList } from './TaskList';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import { MypageDealTaskGroupEnum, MypageDealTaskStatusEnum, MypageDealTaskTypeEnum } from '~/gql/generated/graphql';

const blockClass = 'deal-status';

const DealStatusDealFragment = graphql(`
  fragment DealStatus_deal on Deal {
    id
    dealTasks {
      id
      taskType
      completed
    }
    mypageDealTasks {
      type
      group
      status
    }
    ...TaskList_deal
  }
`);

export type Status = 'documentCreating' | 'registrationApplying' | 'deliveryPreparing' | 'deliveryCompleted';

type Props = {
  deal?: FragmentType<typeof DealStatusDealFragment> | null;
};

export const DealStatus: FunctionComponent<Props> = (props) => {
  const deal = useFragment(DealStatusDealFragment, props.deal);
  const isDeliveryCompleted =
  deal?.mypageDealTasks?.find((task) => task.type === MypageDealTaskTypeEnum.DeliveryCompletion)?.status ===
  MypageDealTaskStatusEnum.Completed || false;
  const [defaultGroup, setDefaultGroup] = useState<MypageDealTaskGroupEnum>(MypageDealTaskGroupEnum.DocumentCreating);
  const [group, setGroup] = useState<MypageDealTaskGroupEnum>(MypageDealTaskGroupEnum.DocumentCreating);

  useEffect(() => {
    const completedMypageDealTaskGroups =
    deal?.mypageDealTasks?.
    filter((task) => task.status === MypageDealTaskStatusEnum.Completed).
    map((task) => task.group) || [];

    if (completedMypageDealTaskGroups.includes(MypageDealTaskGroupEnum.DeliveryCompletion)) {
      setGroup(MypageDealTaskGroupEnum.DeliveryCompletion);
      setDefaultGroup(MypageDealTaskGroupEnum.DeliveryCompletion);
      return;
    }

    if (completedMypageDealTaskGroups.includes(MypageDealTaskGroupEnum.DeliveryPreparation)) {
      setGroup(MypageDealTaskGroupEnum.DeliveryPreparation);
      setDefaultGroup(MypageDealTaskGroupEnum.DeliveryPreparation);
      return;
    }

    if (completedMypageDealTaskGroups.includes(MypageDealTaskGroupEnum.RegistrationApplication)) {
      setGroup(MypageDealTaskGroupEnum.RegistrationApplication);
      setDefaultGroup(MypageDealTaskGroupEnum.RegistrationApplication);
      return;
    }

    setGroup(MypageDealTaskGroupEnum.DocumentCreating);
    setDefaultGroup(MypageDealTaskGroupEnum.DocumentCreating);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal]);

  return (
    <div>
      <p className={styles[`${blockClass}__title`]}>手続き状況</p>
      <div className={styles[`${blockClass}__main`]}>
        <StatusBar
          defaultGroup={defaultGroup}
          group={group}
          clickableDeliveryCompleted={isDeliveryCompleted}
          onChangeGroup={(value) => setGroup(value)} />

        <TaskList group={group} deal={deal} />
      </div>
    </div>);

};