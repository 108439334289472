import { useState, type FunctionComponent } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import { DealAttachmentTypeEnum } from '~/gql/generated/graphql';
import styles from '~/styles/page/mypage/deals/dealsShow/inspection-certificate.module.css';

const blockClass = 'inspection-certificate';

const InspectionCertificateDealFragment = graphql(`
  fragment InspectionCertificate_deal on Deal {
    id
    dealAttachments {
      id
      attachmentType
      url
    }
  }
`);

type Props = {
  deal?: FragmentType<typeof InspectionCertificateDealFragment> | null;
};

export const InspectionCertificate: FunctionComponent<Props> = (props) => {
  const deal = useFragment(InspectionCertificateDealFragment, props.deal);
  const inspectionCertificates =
  deal?.dealAttachments?.
  filter(
    (attachment) => attachment.attachmentType === DealAttachmentTypeEnum.InspectionCertificate && attachment.url
  )?.
  map((attachment) => attachment.url) || [];
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]} onClick={() => setIsOpen(!isOpen)}>
        <p className={styles[`${blockClass}__header-title`]}>車検証</p>
        <img
          src={'/images/icons/ic_arrow_down.svg'}
          alt='arrow down'
          className={[styles[`${blockClass}__header-image`], !isOpen ? styles['close'] : ''].join(' ')} />

      </div>
      {isOpen &&
      <div className={styles[`${blockClass}__main`]}>
          {inspectionCertificates.length > 0 ?
        inspectionCertificates.map((url, index) =>
        <img key={index} src={url!} alt='車検証' className={styles[`${blockClass}__main-image`]} />
        ) :

        <p>車検証はまだありません</p>
        }
        </div>
      }
    </div>);

};