import dayjs from 'dayjs';
import { useState, type FunctionComponent } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import { PaymentMethodEnum, PaymentStatusEnum } from '~/gql/generated/graphql';
import styles from '~/styles/page/mypage/deals/dealsShow/payment-card.module.css';

const blockClass = 'payment-card';

const PaymentCardDealFragment = graphql(`
  fragment PaymentCard_deal on Deal {
    id
    payment {
      id
      deadline
      paymentMethod
      amount
      status
    }
    salesStaff {
      id
      dealerBranch {
        id
        bank {
          bankName
          accountType
          accountNumber
          accountName
          branchCode
          branchName
        }
      }
    }
  }
`);

type Props = {
  deal?: FragmentType<typeof PaymentCardDealFragment> | null;
};

export const PaymentCard: FunctionComponent<Props> = (props) => {
  const deal = useFragment(PaymentCardDealFragment, props.deal);
  const payment = deal?.payment;
  const bank = deal?.salesStaff?.dealerBranch?.bank;
  const [isOpen, setIsOpen] = useState(false);

  if (
  !payment ||
  !payment?.paymentMethod ||
  payment?.paymentMethod === PaymentMethodEnum.CompanyLoan ||
  payment.status === PaymentStatusEnum.Unnecessary)
  {
    return <></>;
  }

  const isConfirmed = payment.status === PaymentStatusEnum.Confirmed;

  const paymentMethodText = (paymentMethod?: PaymentMethodEnum | null) => {
    switch (paymentMethod) {
      case PaymentMethodEnum.Cash:
        return '現金振込';
      case PaymentMethodEnum.BankLoan:
        return '銀行ローン';
      default:
        return '未確定';
    }
  };

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles['left']}>
          <p className={styles[`${blockClass}__header-title`]}>入金</p>
          <p
            className={[styles[`${blockClass}__header-status`], styles[isConfirmed ? 'confirmed' : 'unconfirmed']].join(
              ' '
            )}>

            {isConfirmed ? '確認済み' : '未確認'}
          </p>
        </div>
        <img
          src={'/images/icons/ic_arrow_down.svg'}
          alt='arrow down'
          className={[styles[`${blockClass}__header-image`], !isOpen ? styles['close'] : ''].join(' ')} />

      </div>
      {isOpen &&
      <div className={styles[`${blockClass}__main`]}>
          <div className={styles[`${blockClass}__main-block`]}>
            <p className={styles[`${blockClass}__main-title`]}>お支払い方法</p>
            <p className={styles[`${blockClass}__main-text`]}>{paymentMethodText(payment?.paymentMethod)}</p>
          </div>
          <div className={styles[`${blockClass}__main-block`]}>
            <p className={styles[`${blockClass}__main-title`]}>振込期限</p>
            <p className={styles[`${blockClass}__main-text`]}>
              {payment.deadline ? `〜${dayjs(payment.deadline).format('YYYY/MM/DD')}` : '未確定'}
            </p>
          </div>
          <div className={styles[`${blockClass}__main-block`]}>
            <p className={styles[`${blockClass}__main-title`]}>振込金額</p>
            <p className={styles[`${blockClass}__main-price`]}>
              {payment.amount ? `¥${payment.amount.toLocaleString()}` : '未確定'}
            </p>
          </div>
          {bank && Object.values(bank).some((v) => !!v) &&
        <div className={styles[`${blockClass}__main-block`]}>
              <p className={styles[`${blockClass}__main-title`]}>振込先口座</p>
              <div className={styles[`${blockClass}__main-bank`]}>
                <p>{bank.bankName}</p>
                <p>
                  {bank.branchName}（支店コード：{bank.branchCode}）
                </p>
                <p>
                  {bank.accountType} {bank.accountNumber}
                </p>
                <p>{bank.accountName}</p>
              </div>
            </div>
        }
          <div className={styles[`${blockClass}__main-description`]}>
            <p>期限までに金額をお振込お願いいたします。</p>
            <p>入金手数料はご負担お願いいたします。</p>
          </div>
        </div>
      }
    </div>);

};