import { type FunctionComponent } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import styles from '~/styles/page/mypage/deals/dealsShow/notification-card.module.css';

const blockClass = 'notification-card';

const NotificationCardDealFragment = graphql(`
  fragment NotificationCard_deal on Deal {
    id
    mypageDealNotice
  }
`);

type Props = {
  deal?: FragmentType<typeof NotificationCardDealFragment> | null;
};

export const NotificationCard: FunctionComponent<Props> = (props) => {
  const deal = useFragment(NotificationCardDealFragment, props.deal);

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]}>
        <p className={styles[`${blockClass}__header-title`]}>
          <img
            src='/images/icons/ic_notification.gif'
            alt=''
            className={styles[`${blockClass}__header-img`]}
            width={'18px'} />

          バディカダイレクトからのお知らせ
        </p>
      </div>
      <div className={styles[`${blockClass}__text`]}>
        {deal?.mypageDealNotice ?
        deal.mypageDealNotice.split('\n').map((text, index) => <p key={index}>{text}</p>) :

        <p>お知らせはありません</p>
        }
      </div>
    </div>);

};