import dayjs from 'dayjs';
import { useState, type FunctionComponent } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import styles from '~/styles/page/mypage/deals/dealsShow/order-car.module.css';
import { shortCarModelYear } from '~/utils/carStock';

const blockClass = 'order-car';

const OrderCarDealFragment = graphql(`
  fragment OrderCar_deal on Deal {
    id
    carStock {
      id
      carModelYear
      mileageKm
      carModelName
      shortGradeName
      makeName
      images {
        id
        url
      }
    }
    dealDetail {
      id
      deliveryDate
      deliveryTimeRangeText
    }
  }
`);

type Props = {
  deal?: FragmentType<typeof OrderCarDealFragment> | null;
};

export const OrderCar: FunctionComponent<Props> = (props) => {
  const deal = useFragment(OrderCarDealFragment, props.deal);
  const carStock = deal?.carStock;
  const [isOpen, setIsOpen] = useState(false);

  if (!carStock) {
    return <></>;
  }

  const dealDetail = deal?.dealDetail;
  const deliveryDateText = dealDetail?.deliveryDate ?
  `${dayjs(dealDetail.deliveryDate).format('YYYY年MM月DD日')} ${dealDetail.deliveryTimeRangeText || ''}` :
  '';

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]} onClick={() => setIsOpen(!isOpen)}>
        <p className={styles[`${blockClass}__header-title`]}>注文車両</p>
        <img
          src={'/images/icons/ic_arrow_down.svg'}
          alt='arrow down'
          className={[styles[`${blockClass}__header-image`], !isOpen ? styles['close'] : ''].join(' ')} />

      </div>
      {isOpen &&
      <div className={styles[`${blockClass}__main`]}>
          <div className={styles[`${blockClass}__main-image`]}>
            <img src={carStock.images?.[0]?.url || '/images/pictures/no_image.png'} alt='' />
          </div>
          <div className={styles[`${blockClass}__main-inner`]}>
            <p
            className={styles[`${blockClass}__main-title`]}>
            {`${carStock?.makeName} ${carStock?.carModelName} ${carStock.shortGradeName}`}</p>
            <div className={styles[`${blockClass}__main-features`]}>
              <div className={styles[`${blockClass}__main-features__item`]}>
                <span className={styles[`${blockClass}__main-features__item-icon`]}>年</span>
                <span className={styles[`${blockClass}__main-features__item-text`]}>
                  {shortCarModelYear(carStock.carModelYear) || 'ー'}
                </span>
              </div>
              <div className={styles[`${blockClass}__main-features__item`]}>
                <span className={styles[`${blockClass}__main-features__item-icon`]}>走</span>
                <span className={styles[`${blockClass}__main-features__item-text`]}>
                  {carStock.mileageKm >= 10000 ? `${Math.floor(carStock.mileageKm / 1000) / 10}万` : carStock.mileageKm}
                  km
                </span>
              </div>
              <div className={styles[`${blockClass}__main-features__item`]}>
                <span className={styles[`${blockClass}__main-features__item-icon`]}>車</span>
                <span className={styles[`${blockClass}__main-features__item-text`]}>{carStock.carModelName}</span>
              </div>
              <div className={styles[`${blockClass}__main-features__item`]}>
                <span className={styles[`${blockClass}__main-features__item-icon`]}>G</span>
                <span className={styles[`${blockClass}__main-features__item-text`]}>{carStock.shortGradeName}</span>
              </div>
            </div>
            {deliveryDateText &&
          <div>
                <p className={styles[`${blockClass}__main-delivery__title`]}>納車日</p>
                <p className={styles[`${blockClass}__main-delivery__text`]}>{deliveryDateText}</p>
              </div>
          }
          </div>
        </div>
      }
    </div>);

};